import React from 'react';

// styles
import {
  HeroWrapper,
  HeroContainer,
  GhostButtonStyled,
  ButtonStyled,
  FlexRow,
  CarrierTMSLogo,
  StyledTitle,
  StyledDescription,
} from './Hero.styles';

export function Hero() {
  return (
    <HeroWrapper>
      <HeroContainer>
        <CarrierTMSLogo />
        <StyledTitle>
          Optimize dispatch. Reduce overhead costs.
          <br />
          Scale trucking fleets.
          <br /> All in one TMS.
        </StyledTitle>
        <StyledDescription>
          A truck management software that has all your carrier operations in
          one place,
          <br /> making it easier to manage all areas within your business.
        </StyledDescription>
        <FlexRow>
          <ButtonStyled
            as="a"
            href="https://app.carriertms.io/login?state=checkAccount&utm_source=salescollateral&utm_medium=hyperlink&utm_campaign=carrier-sign-up-campaign-carrier-carriertms-q3-24"
          >
            Free Carrier TMS
          </ButtonStyled>
          <GhostButtonStyled
            as="a"
            href="https://app.carriertms.io/login?utm_source=salescollateral&utm_medium=hyperlink&utm_campaign=carrier-sign-up-campaign-carrier-carriertms-q3-24"
          >
            Login: Carrier TMS
          </GhostButtonStyled>
        </FlexRow>
      </HeroContainer>
    </HeroWrapper>
  );
}
