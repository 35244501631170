import styled from 'styled-components';
import { Section } from '@/components/Grid';
import { screen } from '@/helpers/screen';
import { FlexColumn, FlexRow } from '@/components/Grid/Flex';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PageWrapper = styled.div`
  background: rgb(${getToken('color-background-primary')});
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

export const AlignedSection = styled(Section)`
  text-align: center;

  ${screen.md} {
    text-align: left;
  }
`;

export const BgSection = styled(AlignedSection)`
  margin: 0;
  padding: 0;
  background: rgb(${getToken('color-background-secondary')});
`;

export const Title = styled.h2`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 38px;
  line-height: 47.5px;

  color: rgb(${getToken('color-text-primary')});

  margin-top: 0px;
  margin-bottom: 50px;

  text-align: center;

  ${screen.lg} {
    margin-bottom: 80px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const SubTitle = styled(Title)`
  margin: 20px 0;
`;

export const Card = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  margin: 30px 0;

  ${screen.md} {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
    justify-content: center;
    margin: 30px auto;
  }
`;

export const CardCustom = styled(Card)`
  position: relative;
`;

export const CardSideBySide = styled(Card)`
  ${screen.md} {
    width: 50%;
  }
`;

export const CardGroup = styled(FlexRow)`
  justify-content: space-between;
  flex-direction: column;

  ${screen.md} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const Description = styled.h3`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  margin: 0px;
  font-weight: bold;
  font-size: 19px;
  line-height: 30px;

  ${screen.md} {
    font-size: 21px;
    line-height: 34px;
  }

  ${screen.lg} {
    font-size: 28px;
    line-height: 38px;
  }

  ${screen.xxlg} {
    font-size: 32px;
    line-height: 42px;
  }
`;

export const Text = styled.p`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  margin: 0;
  font-size: 19px;
  line-height: 30px;

  ${screen.lg} {
    font-size: 21px;
    line-height: 34px;
  }

  ${screen.xxlg} {
    font-size: 28px;
    line-height: 38px;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 28px 0;
`;

export const Picture = styled.a`
  background: url(/images/contact/map_bg.jpg) center no-repeat;
  background-size: cover;
  display: none;

  overflow: hidden;
  text-indent: -299em;

  ${screen.md} {
    display: block;
    position: absolute;
    top: -30px;
    height: calc(100% + 60px);

    left: 330px;
    width: calc((100vw / 2) + 20px);
  }

  ${screen.lg} {
    left: 400px;
    width: calc((100vw / 2) + 90px);
  }

  ${screen.xxlg} {
    left: 500px;
    width: calc((100vw / 2) + 190px);
  }
`;
