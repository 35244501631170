// components
import { Container } from 'components/Grid';

// styles
import {
  HeroWrapper,
  Title,
  Paragraph,
  ButtonWrapper,
  CTA,
} from './Hero.styles';

export function Hero() {
  return (
    <HeroWrapper
      bg={{
        xxlarge: 'images/contact/contact-xxlg.jpg',
        large: 'images/contact/contact-lg.jpg',
        medium: 'images/contact/contact-md.jpg',
        small: 'images/contact/contact-sm.jpg',
      }}
    >
      <Container>
        <Title>Contact us</Title>
        <Paragraph>
          Let’s talk! We’d love to hear about your logistics challenges and
          discuss how our tailored logistics solutions and technology can help
          you lower costs, increase efficiency, and digitize transportation.
        </Paragraph>
        <ButtonWrapper>
          <CTA as="a" href="#contact-form">
            <span> Get In Touch </span>
          </CTA>
        </ButtonWrapper>
      </Container>
    </HeroWrapper>
  );
}
