import styled from 'styled-components';

//components
import { MirandaGradientButton } from '@/components/Button';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const HeroWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  text-align: center;

  background-color: rgb(${getToken('color-background-secondary-inverted')});
  background-image: url(/${(props) => props.bg.small});
  background-position: center center;
  background-size: cover;

  padding: 80px 0;

  ${screen.md} {
    min-height: 330px;
    background-position: top center;
    background-image: url(/${(props) => props.bg.medium});
    text-align: left;
  }

  ${screen.lg} {
    min-height: 490px;

    background-image: url(/${(props) => props.bg.large});
  }

  ${screen.xxlg} {
    padding: 100px 0;
    min-height: 610px;

    background-image: url(/${(props) => props.bg.xxlarge});
  }
`;

export const Title = styled.h1`
  color: rgb(${getToken('color-text-inverted')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 38px;
  line-height: 47.5px;

  width: 100%;
  text-align: center;
  margin: auto;
  text-wrap: balance;

  ${screen.lg} {
    max-width: 633px;
  }

  ${screen.lg} {
    max-width: 839px;
    font-size: 48px;
    line-height: 60px;
  }

  ${screen.xxlg} {
    line-height: 72.5px;
    font-size: 58px;
    line-height: 72.5px;
    max-width: 1099px;
  }
`;

export const Paragraph = styled.p`
  color: rgb(${getToken('color-text-inverted')});
  font-family: ${getToken('platform-font-title')};
  font-size: 16px;
  line-height: 24px;

  margin: 16px auto 20px;
  text-align: center;
  text-wrap: balance;

  ${screen.lg} {
    max-width: 545px;
    margin: 20px auto 28px;
  }

  ${screen.lg} {
    max-width: 672px;

    font-size: 20px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    margin: 24px auto 32px;
    max-width: 940px;

    font-size: 26px;
    line-height: 39px;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
`;

export const CTA = styled(MirandaGradientButton)`
  border-color: transparent;
  color: rgb(${getToken('color-text')});
  margin: auto;
`;
