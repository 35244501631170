//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import { WeInNumbers } from '@/containers-m/WeInNumbers';
import { BackgroundImageSection } from '@/containers-m/BackgroundImageSection';

// context containers
import {
  Hero,
  CarouselSection,
  ImageGridSection,
  VideosSection,
  FeatureSection,
} from './containers-m/';

//styles
import { PageWrapper } from './Careers.styles';
import { CarouselSlider } from '@/containers-m/CarouselSlider';

const bgSection1 = {
  title: <>Get to know our teams</>,
  description:
    'Whether our Loadies are working remote or at one of our global offices, we are always connected through our continual development of a community of dedicated employees and logistics professionals. No matter where you are located, you will find an environment of teamwork, fun and celebration of our achievements.',
  image: {
    xxlarge: 'images/careers/teams/loadies-mosaic-xxlg.jpg',
    large: 'images/careers/teams/loadies-mosaic-lg.jpg',
    medium: 'images/careers/teams/loadies-mosaic-md.jpg',
    small: 'images/careers/teams/loadies-mosaic-sm.jpg',
  },
  CTA: {
    label: 'Explore open positions',
    link: 'https://jobs.lever.co/loadsmart/',
    external: true,
  },
};

const bgSection2 = {
  title: 'Become a Loadie!',
  description:
    'We’re always looking to connect with world-class thinkers, strategists, doers, and leaders. Check out our open positions below.',
  image: {
    xxlarge: 'images/careers/become/become-loadie-xxlg.jpg',
    large: 'images/careers/become/become-loadie-lg.jpg',
    medium: 'images/careers/become/become-loadie-md.jpg',
    small: 'images/careers/become/become-loadie-sm.jpg',
  },
  CTA: {
    label: 'Learn more',
    link: 'https://jobs.lever.co/loadsmart/',
    external: true,
  },
  biggerBg: true,
};

const team_slides = [
  {
    title: 'People and Experiences',
    description:
      'We ensure our people come first. Training and development begin day one so that our teams have the tools they need to achieve amazing results.',
  },
  {
    title: 'Engineering',
    description:
      'We are the nerds. By leveraging the power of automation and approaching engineering as a craft, we deliver high quality software to move more with less.',
  },
  {
    title: 'Product & Design',
    description:
      'We create products our customers love. Using data and sound-research, our cross-functional teams crunch the complexity of logistics with users in mind.',
  },
  {
    title: 'Operations',
    description:
      'We put in the work to make sure freight gets from Point A to Point B. Working alongside our technology, we are setting a new benchmark for logistics service.',
  },
  {
    title: 'Sales & Marketing',
    description:
      'We build customer relationships and brand affinity face-to-face and digitally. Our clients come for the best-in-class products, but stay for the brand.',
  },
  {
    title: 'Pricing Ops',
    description:
      'We price the present and future of freight. Using industry expertise and market trends, we provide unrivaled value to our shipper and carrier partners.',
  },
  {
    title: 'Finance & BI',
    description:
      'We are driven by numbers. By providing data and intelligence, our analysts help make the most well-informed financial decisions for the business.',
  },
  {
    title: 'Admin & Legal',
    description:
      'We anticipate and take on bumps in the road. We support our people, operations, and technology so they can continuously exceed expectations.',
  },
];

const rewards_slides = [
  {
    title: 'Healthcare*',
    description:
      'We offer competitive medical, dental, and vision coverage for you and your family.',
  },
  {
    title: 'Claim Advocacy with MMA*',
    description: 'Expert advocacy and guidance on benefits and claims.',
  },
  {
    title: 'HSA/FSA*',
    description:
      'You can use pre-tax dollars to pay for healthcare costs for you and your dependents.',
  },
  {
    title: 'DCFSA*',
    description:
      'We offer DCFSA to help parents reduce their total taxable income when paying for a childcare.',
  },
  {
    title: 'LPFSA*',
    description:
      'On eligible plans, LPFSA helps pay for vision and dental expenses that are not fully covered by your insurance.',
  },
  {
    title: 'Life and AD&D Insurance*',
    description:
      'Loadsmart provides both Life and Accidental Death and Dismemberment insurance at no cost to you with the options to increase coverage at your own expense.',
  },
  {
    title: 'Short Term/Long Term Disability Insurances*',
    description:
      'Loadsmart provides short and long-term disability coverage at no cost to you.',
  },
  {
    title: 'Flexible PTO/Holidays',
    description:
      'Enjoy paid time off without a limit, with manager approval and national holidays of your location.',
  },
  {
    title: '401K with Match*',
    description:
      'We support our employees’ retirement goals by offering a per pay period match to our Traditional and Roth 401K plans.',
  },
  {
    title: 'Parental leave',
    description:
      'The primary caregiver is able to take three months of fully paid leave and one additional month of working half days for a total of four months of leave.',
  },
  {
    title: 'Company Equity',
    description:
      'Ever wanted to have a stake in a company? Upon hire, Loadsmart employees are granted options that vest over a four-year period.',
  },
  {
    title: 'Team Building Events',
    description:
      'Enjoy local company sponsored events to meet and network with fellow Loadies: from hackatons to local meetups, we love collaborating while having fun.',
  },
];

const Careers = () => {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <Hero />
        <WeInNumbers />
        <VideosSection />
        <BackgroundImageSection content={bgSection1} variant="small-text" />
        <CarouselSlider content={team_slides} />
        <CarouselSection />
        <FeatureSection />
        <ImageGridSection />
        <CarouselSlider
          content={rewards_slides}
          title={'Total Rewards at Loadsmart'}
          footer={'* Designated benefits are only available to U.S. employees.'}
        />
        <BackgroundImageSection content={bgSection2} />
      </>
      <Footer />
    </PageWrapper>
  );
};

export default Careers;
export { Careers };
