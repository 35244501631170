/* eslint-disable @next/next/no-img-element */
import { useState } from 'react';

// components
import { Container } from 'components/Grid';

// styles
import {
  SectionWrapper,
  TitleWrapper,
  CTAWrapper,
  Title,
  IntroText,
  MediaWrapper,
  VideoWrapper,
  ClosingIcon,
  Thumbnail,
  Picture,
  PlayingIcon,
  LogoWrapper,
  ContenWrapper,
} from './FreightSection.styles';
import { MirandaGradientButton } from '@/components/Button/BaseButton';

//vendor
import ReactPlayer from 'react-player/lazy';

//icons
import { IconClose } from '@loadsmart/icons';
import IconPlay from '@/public/images/carrier-tms/video-hero/icon-play.svg';

//logo
import FIAI from '@/public/images/logos/logo-freightintel-ai.svg';

export function FreightSection() {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <SectionWrapper>
      <Container>
        <TitleWrapper>
          <LogoWrapper>
            <FIAI width="418" height="48" />
          </LogoWrapper>

          <Title>Unlocking Insights with advanced machine learning</Title>
          <IntroText>
            FreightIntel AI analyzes complex data sets, identifies trends, and
            delivers customized recommendations based on patterns it uncovers.
          </IntroText>
        </TitleWrapper>
        <MediaWrapper>
          <VideoWrapper className={playVideo ? `active` : ``}>
            <ClosingIcon
              onClick={() => {
                setPlayVideo(false);
              }}
            >
              <IconClose />
            </ClosingIcon>
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=_rf7_1NE8-M?rel=0"
              controls
              playing={playVideo}
              width="100%"
              height="100%"
            />
          </VideoWrapper>
          <Thumbnail
            className={playVideo ? `inactive` : ``}
            onClick={() => {
              setPlayVideo(true);
            }}
            role="button"
          >
            <Picture>
              <source
                srcSet={`/images/home/freightintel/freightintelai-thumb.jpg`}
                media="(min-width: 1920px)"
              />
              <source
                srcSet={`/images/home/freightintel/freightintelai-thumb.jpg`}
                media="(min-width: 1024px)"
              />
              <source
                srcSet={`/images/home/freightintel/freightintelai-thumb.jpg`}
                media="(min-width: 768px)"
              />
              <img
                srcSet={`/images/home/freightintel/freightintelai-thumb.jpg`}
                alt=""
                width="677"
                height="520"
              />
            </Picture>
            <PlayingIcon>
              <IconPlay />
            </PlayingIcon>
          </Thumbnail>
        </MediaWrapper>
        <ContenWrapper>
          <p>
            FreightIntel AI functions as a seasoned transportation expert,
            capable of quickly grasping the intricacies of logistics without the
            need for additional input. Shippers simply connect their data and
            receive insightful, data-driven recommendations.
          </p>
          <p>
            The system combines a shipper’s logistics information with
            Loadsmart’s robust benchmarking dataset and state-of-the-art AI
            technology to create insights tailored to the unique needs of each
            business.
          </p>
        </ContenWrapper>
        <CTAWrapper>
          <MirandaGradientButton
            as="a"
            href="https://lp.loadsmart.com/freight-intel-ai?utm_source=loadsmart&utm_medium=cta&utm_campaign=fi-ai-midmarket-fintech-q3-24&utm_content=home-page"
            target="_blank"
          >
            <span>Learn More</span>
          </MirandaGradientButton>
        </CTAWrapper>
      </Container>
    </SectionWrapper>
  );
}
